<template>
  <div>
    <div class="d-flex align-center justify-center pt-12 mt-12">
      <img src="@/assets/images/home/jihuo.png" alt />
      <div class="ml-6">
        <p class="text-h5 pt-4">{{$t('激活成功')}}</p>
        <a :href="links">
          <v-btn color="primary">{{$t('去购物')}}</v-btn>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      links: ""
    };
  },
  mounted() {
    this.links = this.$route.query.link;
  }
};
</script>